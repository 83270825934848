<template>
  <Popup @close="redirect">
    <div class="row mt-4 justify-content-center text-center">
      <div class="col-7 col-md-6 text-center">
        <div v-if="!status" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <TickAnimation v-else-if="status === 'success'"/>
        <ExclamationAnimation v-else/>
      </div>
      <div class="col-12 mb-3 text-center medium font23 green-text" v-if="status">
        <span v-if="status === 'success'">Alhamdulillah!<br/>Your DailySadaqah has started!</span>
        <span v-else class="text-capitalize">{{ status }}</span>
      </div>
      <div class="col-10 mb-3 text-center">
        <span v-if="status === 'success'">Your daily donations will come together with thousands of others donating each day to make a huge difference around the world!</span>
        <span v-else>{{message}}</span>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue')),
    ExclamationAnimation: defineAsyncComponent(() => import('@/components/animations/ExclamationAnimation.vue'))
  },
  props: ['subscriptionRef'],
  emits: ['close'],
  name: 'Daily Sadaqah Complete Popup',
  data () {
    return {
      message: '',
      status: null,
      isLoading: false
    }
  },
  async mounted () {
    await this.checkTransactionStatus()
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    ...mapActions(['verifyStripeSubscription']),
    async checkTransactionStatus () {
      if (this.subscriptionRef) {
        const result = await this.verifyStripeSubscription(this.subscriptionRef)
        if (result) {
          this.message = result.data.result.description
          this.status = result.data.result.status.toLowerCase()
        } else {
          this.message = 'There was an issue with your payment, please try again later or contact support'
        }
        this.startTime()
      }
    },
    startTime () {
      setTimeout(() => {
        this.redirect()
      }, 12000)
    },
    redirect () {
      if (this.status === 'success') {
        if (this.user) {
          this.$router.push('/managesadaqah')
        } else {
          this.$router.push('/')
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>
